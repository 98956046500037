/*!

*/
import React, { Component } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import axios from 'axios';
//import openSocket from 'socket.io-client';

class Documents extends Component {

  constructor(props) {
    super(props);
    this.state = {
      body: {}, // this is the body from /user
      documentId: "",
    };
    this.getDocumentId();
  }

  async getDocumentId() {
    console.log("TableList.js - sendEmail");
    try {
      let response = await axios.get("https://backend.customers-web.automation.multitray.nl/get-document-id");
      this.setState({documentId: response.data});
      console.log(response.data);
    }
    catch (error) {
      console.error("ERR: " + error)
    }
  }


  render() {

    return (
      <div style={{height: "100%"}}>
      <iframe src={"https://drive.google.com/file/d/" + this.state.documentId + "/preview"} width="100%" height="100%"></iframe>
      </div>
    );
  }
}

export default Documents;
