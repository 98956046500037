import { EventEmitter } from "events";
import axios from 'axios';

class DataStore extends EventEmitter {
    constructor() {
        super();
        this.c1data = [];
        this.token = {};
    }

    setC1Data(data) {
        this.c1data = data.c1data;
        this.token = data.token;
        console.log("DataStore");
        //console.log(data);
        this.emit("setC1DataComplete", data.c1data, data.token);
    }

    
}

const dataStore = new DataStore();

export default dataStore;
