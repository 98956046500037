/*!

*/
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Nav from "react-bootstrap/Nav";


class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth
    };
  }
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  updateDimensions() {
    this.setState({ width: window.innerWidth });
  }
  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }
  render() {
    const sidebarBackground = {
      backgroundImage: "url(" + this.props.image + ")"
    };
    return (
      <div>
      <div
        id="sidebar"
        className="sidebar"
        data-color={"tint"}
        data-image={this.props.image}
      >
          {this.props.hasImage ? (
            <div className="sidebar-background" style={sidebarBackground} />
          ) : (
            null
          )}
        <div className="logo">
          <h4 className="title" style={{ fontWeight: "bold" }}>CUSTOMER's WEB</h4>
        </div>

        <div className="sidebar-wrapper">
          <Nav defaultActiveKey="/home" className="flex-column" style={{ paddingTop: "20px", color: "white" }}>
            {this.props.routes.map((prop, key) => {
              var pth = this.props.location.pathname.replace('/admin', '');
              if (!prop.redirect && (prop.show == true || pth == prop.path))
                return (
         
                  <Nav.Link
                    eventKey={key}
                    className={
                      prop.upgrade
                        ? "active active-pro"
                        : this.activeRoute(prop.layout + prop.path)
                    }
                    key={key}
                    href={prop.layout + prop.path}
                    
                  >
                  <div className={"row"}>
                    <div className={"col-2"}>
                      <i className={prop.icon} style={{ fontSize: "2.5em", minHeight: "40px" ,fontWeight: "bold" }} />
                    </div>
                    <div className={"col-8"}>
                      <div style={{ textAlign: "left",fontWeight: "bold", margin: "10px" }}>{prop.name}</div>
                    </div>
                  </div>

                  
                  </Nav.Link>

                  
                );
              return null;
            })}
          </Nav>
        </div>
      </div>
      </div>
    );
  }
}

export default Sidebar;
