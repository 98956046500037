/*!

*/
import React, { Component } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import Accordion from "react-bootstrap/Accordion";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


import axios from 'axios';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import DataStore from '../components/DataStore.js';
import dateFormat from 'dateformat';
//import openSocket from 'socket.io-client';

//const socket = openSocket('http://10.54.6.8:8000');

am4core.useTheme(am4themes_animated);
var chart = am4core.create("chartdiv", am4charts.TreeMap);
chart.logo.disabled = true;
var level1 = chart.seriesTemplates.create("0");
var level1_column = level1.columns.template;
level1_column.tooltipText =  "Name : {name}\n Trays  : {trays}\n Boxes : {boxes}\n Pallets: {pallets}";
let level1_bullet = level1.bullets.push(new am4charts.LabelBullet());
level1_bullet.locationY = 0.5;
level1_bullet.locationX = 0.5;
level1_bullet.label.text = "Name : {name}\n Trays  : {trays}\n Boxes : {boxes}\n Pallets: {pallets}";
level1_bullet.label.fill = am4core.color("#fff");
//level1_bullet.label.textAlign = "middle";

class TableList2 extends Component {

  constructor() {
    super();
    var now = new Date();
    this.state = {
      items: [],
      sortedData: [],
      treemapSorting: 1,
      tableSorting: 0,
      selectedItem: {},
      sendMessageModal: false,
      requestedAmountTrays: 0,
      requestedAmountBoxes: 0,
      requestedAmountPallets: 0,
      anotherRequest: "",
      deliveryDate: now,
      estimateDate: now,

    };

  }

  componentDidMount() {
    DataStore.on("setC1DataComplete", this.getCustomerStorageItems.bind(this));
    //this.getCustomerStorageItems();
  }
  componentWillUnmount() {
    DataStore.removeListener("setC1DataComplete", this.getCustomerStorageItems);
  }


  getFusionAuthBody(body) {
    this.setState({ fusionAuthBody: body })

  }

  async getCustomerStorageItems(data, token) {
    console.log("TableList.js - getCustomerStorageItems");
    console.log(data);
    data.forEach(function(item) {
      var boxes = 0;
      if (item.Qty > 0 && item.FormingsPerPallet > 0 && item.FormingsPerBox > 0) {
        boxes = Math.round(item.Qty / item.FormingsPerBox);
      }
      item.boxes = boxes;
    });

    var sortedData = data;
    sortedData.sort(this.sortByCliNameAsc);
    this.setState({ items: data, sortedData: sortedData, token });
  }

  sortByNameDesc(a, b) {
    // Use toUpperCase() to ignore character casing
    const bandA = a.SIName.toUpperCase();
    const bandB = b.SIName.toUpperCase();
    let comparison = 0;
    if (bandA < bandB) {
      comparison = 1;
    }
    else if (bandA > bandB) {
      comparison = -1;
    }
    return comparison;
  }

  sortByNameAsc(a, b) {
    // Use toUpperCase() to ignore character casing
    const bandA = a.SIName.toUpperCase();
    const bandB = b.SIName.toUpperCase();
    let comparison = 0;
    if (bandA > bandB) {
      comparison = 1;
    }
    else if (bandA < bandB) {
      comparison = -1;
    }
    return comparison;
  }

  sortByCliNameDesc(a, b) {
    // Use toUpperCase() to ignore character casing
    const bandA = a.CliSIName.toUpperCase();
    const bandB = b.CliSIName.toUpperCase();
    let comparison = 0;
    if (bandA < bandB) {
      comparison = 1;
    }
    else if (bandA > bandB) {
      comparison = -1;
    }
    return comparison;
  }

  sortByCliNameAsc(a, b) {
    // Use toUpperCase() to ignore character casing
    const bandA = a.CliSIName.toUpperCase();
    const bandB = b.CliSIName.toUpperCase();
    let comparison = 0;
    if (bandA > bandB) {
      comparison = 1;
    }
    else if (bandA < bandB) {
      comparison = -1;
    }
    return comparison;
  }

  sortByTraysDesc(a, b) {
    // Use toUpperCase() to ignore character casing
    const bandA = a.Qty;
    const bandB = b.Qty;
    let comparison = 0;
    if (bandA < bandB) {
      comparison = 1;
    }
    else if (bandA > bandB) {
      comparison = -1;
    }
    return comparison;
  }

  sortByTraysAsc(a, b) {
    // Use toUpperCase() to ignore character casing
    const bandA = a.Qty;
    const bandB = b.Qty;
    let comparison = 0;
    if (bandA > bandB) {
      comparison = 1;
    }
    else if (bandA < bandB) {
      comparison = -1;
    }
    return comparison;
  }

  sortByBoxesDesc(a, b) {
    // Use toUpperCase() to ignore character casing
    const bandA = a.boxes;
    const bandB = b.boxes;
    let comparison = 0;
    if (bandA < bandB) {
      comparison = 1;
    }
    else if (bandA > bandB) {
      comparison = -1;
    }
    return comparison;
  }

  sortByBoxesAsc(a, b) {
    // Use toUpperCase() to ignore character casing
    const bandA = a.boxes;
    const bandB = b.boxes;
    let comparison = 0;
    if (bandA > bandB) {
      comparison = 1;
    }
    else if (bandA < bandB) {
      comparison = -1;
    }
    return comparison;
  }

  thousands_separators(num) {
    var num_parts = num.toString().split(".");
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return num_parts.join(".");
  }

  setTreemapSorting(e) {
    this.setState({ treemapSorting: Number(e) });
  }

  setTableSorting(e) {
    //console.log(e);
    this.setState({ tableSorting: Number(e) });
    var sortedData = this.state.items;
    if (e == 0) sortedData.sort(this.sortByCliNameAsc);
    if (e == 1) sortedData.sort(this.sortByCliNameDesc);
    if (e == 2) sortedData.sort(this.sortByNameAsc);
    if (e == 3) sortedData.sort(this.sortByNameDesc);
    if (e == 4) sortedData.sort(this.sortByTraysAsc);
    if (e == 5) sortedData.sort(this.sortByTraysDesc);
    if (e == 6) sortedData.sort(this.sortByBoxesAsc);
    if (e == 7) sortedData.sort(this.sortByBoxesDesc);

    this.setState({ sortedData: sortedData });
  }


  sendMessageModalClick(item) {
    this.setState({ sendMessageModal: true, selectedItem: item });
  }


  sendOrder() {
    var text = "";
    text += "<h3>NEW REQUEST FROM MULTITRAY CUSTOMER'S WEB</h3>"
    text += "<br />Customer: "
    text += this.state.token.preferred_username;
    text += "<br />Email: "
    text += this.state.token.email;
//    text += "<br />Phone: "
//    text += this.state.token.mobilePhone;
    text += "<br />"
    text += "We would like to order this: "
    text += "<br />Client Product Name: "
    text += this.state.selectedItem.CliSIName;
    text += "<br />Product: "
    text += this.state.selectedItem.SIName;
    text += "<br />Amount of trays: "
    text += this.state.requestedAmountTrays;
    text += "<br />Amount of boxes: "
    text += this.state.requestedAmountBoxes;
    text += "<br />Amount of pallets: "
    text += this.state.requestedAmountPallets;
    text += "<br />Delivery Date: "
    text += dateFormat(new Date(this.state.deliveryDate), "yyyy-mm-dd HH:MM:ss");
    this.sendEmail("New Customer's Order", text);
    this.setState({ sendMessageModal: false, })
  }

  sendProductionOrder() {
    var text = "";
    text += "<h3>NEW REQUEST FROM MULTITRAY CUSTOMER'S WEB</h3>"
    text += "<br />Customer: "
    text += this.state.token.preferred_username;
    text += "<br />Email: "
    text += this.state.token.email;
//    text += "<br />Phone: "
//    text += this.state.token.mobilePhone;
    text += "<br />"
    text += "We would like to order this: "
    text += "<br />Client Product Name: "
    text += this.state.selectedItem.CliSIName;
    text += "<br />Product: "
    text += this.state.selectedItem.SIName;
    text += "<br />Amount of trays: "
    text += this.state.requestedAmountTrays;
    text += "<br />Amount of boxes: "
    text += this.state.requestedAmountBoxes;
    text += "<br />Amount of pallets: "
    text += this.state.requestedAmountPallets;
    text += "<br />Estimated Date: "
    text += dateFormat(new Date(this.state.estimateDate), "yyyy-mm-dd HH:MM:ss");
    this.sendEmail("New Customer's Production Order", text);
    this.setState({ sendMessageModal: false, })
  }

  sendRequest() {
    var text = "";
    text += "<h3>NEW REQUEST FROM MULTITRAY CUSTOMER'S WEB</h3>"
    text += "<br />Customer: "
    text += this.state.token.preferred_username;
    text += "<br />Email: "
    text += this.state.token.email;
//    text += "<br />Phone: "
//    text += this.state.token.mobilePhone;
    text += "<br />"
    text += this.state.anotherRequest;
    this.sendEmail("New Customer's Request", text);
    this.setState({ sendMessageModal: false, })
  }

  async sendEmail(subject, text) {
    console.log("TableList.js - sendEmail");
    try {
      const dt = { subject, text, email: this.state.token.email };

      const requestOptions = {
        //credentials: 'include', // fetch won't send cookies unless you set credentials
        method: 'POST',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' },
        body: dt
      };

      //fetch('https://backend.customers-web.automation.multitray.nl/send-email', requestOptions)
      //  .then(response => console.log(response.body))

      let response = await axios.post("https://backend.customers-web.automation.multitray.nl/send-email", dt);
      //return response;
    }
    catch (error) {
      console.error("ERR: " + error)
    }
  }

  handleDeliveryDateChange = date => {
    this.setState({
      deliveryDate: date
    });
  };

  handleEstimateDateChange = date => {
    this.setState({
      estimateDate: date
    });
  };



  render() {
    var t = this;
    var pallets = 0;
    var boxes = 0;
    var trays = 0;
    var treeMapData = [];
    this.state.sortedData.forEach(function(item) {
      if (item.Qty > 0 && item.FormingsPerPallet > 0 && item.FormingsPerBox > 0) {
        pallets += Math.round(item.Qty / item.FormingsPerPallet);
        boxes += Math.round(item.Qty / item.FormingsPerBox);
        trays += item.Qty;
        if (item.Qty > 0) {
          var val;
          if (t.state.treemapSorting == 1) val = item.Qty;
          if (t.state.treemapSorting == 2) val = item.Qty / item.FormingsPerBox;
          var obj = {
            "name": item.SIName,
            "value": val,
            "trays": Number(item.Qty),
            "boxes": item.Qty / item.FormingsPerBox,
            "pallets": item.Qty / item.FormingsPerPallet,
          };
          treeMapData.push(obj);
        }
      }
    })
    chart.data = treeMapData;
    chart.dataFields.name = "name";
    chart.dataFields.value = "value";
    chart.dataFields.trays = "trays";
    chart.dataFields.boxes = "boxes";
    chart.dataFields.pallets = "pallets";

    var tableSortingButtonItems = ["SORT BY CLI NAME 0 TO Z", "SORT BY CLI NAME Z TO 0", "SORT BY NAME 0 TO Z", "SORT BY NAME Z TO 0", "SORT BY TRAYS SMALLEST FIRST", "SORT BY TRAYS LARGEST FIRST", "SORT BY BOXES SMALLEST FIRST", "SORT BY BOXES LARGEST FIRST"];
    var tableSortingButtonTitle;

    tableSortingButtonTitle = tableSortingButtonItems[this.state.tableSorting];

    return (
      <div className="content">
          <div className={"row"} style={{ marginBottom: "30px" }} >
            <div className={"col-3"}>
              <Card >
                <Card.Header >
                  <div className={"row"}>
                    <div className={"col-4"}>
                      <i className="fa fa-cubes text-warning" style={{ fontSize: "3em", minHeight: "64px" ,fontWeight: "bold" }} />
                    </div>
                    <div className={"col-8"}>
                      <div style={{ textAlign: "center",fontWeight: "bold" }}>Products in warehouse</div>
                    </div>
                  </div>
                </Card.Header >
                <Card.Body>
                  <Card.Text style={{ textAlign: "center",fontWeight: "bold" }}>
                    {this.state.items.length}
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>

            <div className={"col-3"}>
              <Card >
                <Card.Header >
                  <div className={"row"}>
                    <div className={"col-4"}>
                      <i className="pe-7s-box2 text-success" style={{ fontSize: "3em", minHeight: "64px" ,fontWeight: "bold" }} />
                    </div>
                    <div className={"col-8"}>
                      <div style={{ textAlign: "center",fontWeight: "bold" }}>Pallets in warehouse</div>
                    </div>
                  </div>
                </Card.Header >
                <Card.Body>
                  <Card.Text style={{ textAlign: "center",fontWeight: "bold" }}>
                    {pallets}
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>

            <div className={"col-3"}>
              <Card >
                <Card.Header >
                  <div className={"row"}>
                    <div className={"col-4"}>
                      <i className="pe-7s-box1 text-danger" style={{ fontSize: "3em", minHeight: "64px" ,fontWeight: "bold" }} />
                    </div>
                    <div className={"col-8"}>
                      <div style={{ textAlign: "center",fontWeight: "bold" }}>Boxes in warehouse</div>
                    </div>
                  </div>
                </Card.Header >
                <Card.Body>
                  <Card.Text style={{ textAlign: "center",fontWeight: "bold" }}>
                    {boxes}
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>

            <div className={"col-3"}>
              <Card >
                <Card.Header >
                  <div className={"row"}>
                    <div className={"col-4"}>
                      <i className="fa fa-th-large text-primary" style={{ fontSize: "3em", minHeight: "64px" ,fontWeight: "bold" }} />
                    </div>
                    <div className={"col-8"}>
                      <div style={{ textAlign: "center",fontWeight: "bold" }}>Trays in warehouse</div>
                    </div>
                  </div>
                </Card.Header >
                <Card.Body>
                  <Card.Text style={{ textAlign: "center",fontWeight: "bold" }}>
                    {this.thousands_separators(trays)}
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </div>




          <div className={"row"} style={{ marginBottom: "30px" }}>
            <div className={"col-12"}>
              <Card>
                <Card.Header>
                  <h4 className="title" style={{ float: "left" }}>Table of all products in the Multitray warehouse</h4>
                  <DropdownButton
                    title={tableSortingButtonTitle}
                    className="sdfgh"
                    style={{ margin: "0px", float: "right" }}
                  >
                    <Dropdown.Item onClick={()=>this.setTableSorting('0')}>{tableSortingButtonItems[0]}</Dropdown.Item>
                    <Dropdown.Item onClick={()=>this.setTableSorting('1')}>{tableSortingButtonItems[1]}</Dropdown.Item>
                    <Dropdown.Item onClick={()=>this.setTableSorting('2')}>{tableSortingButtonItems[2]}</Dropdown.Item>
                    <Dropdown.Item onClick={()=>this.setTableSorting('3')}>{tableSortingButtonItems[3]}</Dropdown.Item>
                    <Dropdown.Item onClick={()=>this.setTableSorting('4')}>{tableSortingButtonItems[4]}</Dropdown.Item>
                    <Dropdown.Item onClick={()=>this.setTableSorting('5')}>{tableSortingButtonItems[5]}</Dropdown.Item>
                    <Dropdown.Item onClick={()=>this.setTableSorting('6')}>{tableSortingButtonItems[6]}</Dropdown.Item>
                    <Dropdown.Item onClick={()=>this.setTableSorting('7')}>{tableSortingButtonItems[7]}</Dropdown.Item>
                  </DropdownButton>
                </Card.Header>
                  <Table>
                    <thead>
                      <tr>
                        <th >CLI NAME</th>
                        <th >NAME</th>
                        <th >DESCRIPTION</th>
                        <th >TRAYS</th>
                        <th >BOXES</th>
                        <th >TRAYS PER BOX</th>
                        <th >TRAYS PER PALLET</th>
                        <th >ACTION</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.items.map((item, key) => {
                        return (
                          <tr key={key}>
                              <td >{item.CliSIName}</td>
                              <td >{item.SIName}</td>
                              <td >{item.CliSIDesc}</td>
                              <td >{item.Qty}</td>
                              <td >{item.boxes}</td>
                              <td >{item.FormingsPerBox}</td>
                              <td >{item.FormingsPerPallet}</td>
                              <td></td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
              </Card>
            </div>
          </div>



          <div className={"row"} style={{ marginBottom: "30px" }}>
            <div className={"col-12"}>
              <Card>
                <Card.Header>
                  <h4 className="title" style={{ float: "left" }}>TreeMap chart of all products</h4>
                  <DropdownButton
                    alignRight
                    title={(this.state.treemapSorting == 1) ? "SORT BY TRACES" : "SORT BY BOXES"}
                    style={{ margin: "0px", float: "right", backgroundColor: "#8cc63f" }}
                  >
                    <Dropdown.Item eventKey="1" value="1" onClick={()=>this.setTreemapSorting('1')}>SORT BY TRACES</Dropdown.Item>
                    <Dropdown.Item eventKey="2" value="2" onClick={()=>this.setTreemapSorting('2')}>SORT BY BOXES</Dropdown.Item>
                  </DropdownButton>
                </Card.Header>
              <div id="chartdiv" style={{ width: "100%", height: "500px" }}></div>
              </Card>
            </div>
          </div>
          
          
          

        <Modal show={this.state.sendMessageModal} onHide={()=> {this.setState({ sendMessageModal: false})}} dialogClassName="modalDialog" >
          <Modal.Header closeButton>
            <Modal.Title>CREATE NEW REQUEST</Modal.Title>
          </Modal.Header>
        
          <Modal.Body>
            <h4 className="title" >Product Name: {this.state.selectedItem.SIName}</h4>
            <Accordion defaultActiveKey="0">
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="0">
                    NEW ORDER
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body style={{ minHeight: "300px", }}>
                    <h4 className="title" style={{ marginBottom: "20px", }} >We would like to order this amount of the product.</h4>
                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                        <InputGroup.Text style={{ width: "200px"}}>Delivery Date:</InputGroup.Text>
                      </InputGroup.Prepend>
                          <Form.Control as={DatePicker}
                          className={"text-black form-control"}
                          popperPlacement="right"
                            dateFormat="yyyy-MM-dd"
                            selected={this.state.deliveryDate}
                            onChange={this.handleDeliveryDateChange}
                          />
                    </InputGroup>
                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                        <InputGroup.Text style={{ width: "200px"}}>Amount of trays:</InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl value={this.state.requestedAmountTrays} onChange={(e) => {this.setState({requestedAmountTrays: e.target.value, requestedAmountPallets: Number(e.target.value / this.state.selectedItem.FormingsPerPallet).toFixed(1), requestedAmountBoxes: Number(e.target.value / this.state.selectedItem.FormingsPerBox).toFixed(1)})}}/>
                      <InputGroup.Prepend>
                        <InputGroup.Text style={{ width: "200px"}}> = {this.state.requestedAmountBoxes} boxes</InputGroup.Text>
                      </InputGroup.Prepend>
                    </InputGroup>
                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                        <InputGroup.Text style={{ width: "200px"}}>Amount of pallets:</InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl value={this.state.requestedAmountPallets} onChange={(e) => {this.setState({requestedAmountPallets: e.target.value, requestedAmountTrays: e.target.value * this.state.selectedItem.FormingsPerPallet, requestedAmountBoxes: Number(e.target.value * this.state.selectedItem.FormingsPerPallet / this.state.selectedItem.FormingsPerBox).toFixed(1)})}}/>
                      <InputGroup.Prepend>
                        <InputGroup.Text style={{ width: "200px"}}> = {this.state.requestedAmountBoxes} boxes</InputGroup.Text>
                      </InputGroup.Prepend>
                    </InputGroup>
                    <Button variant="warning" style={{ float: "right", marginBottom: "10px"}} onClick={this.sendOrder.bind(this)}>SEND ORDER</Button>
                    </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="1">
                    NEW PRODUCTION ORDER
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    <h4 className="title" style={{ marginBottom: "20px", }} >We would like to order this amount of the product.</h4>
                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                        <InputGroup.Text style={{ width: "200px"}}>Estimated call off date:</InputGroup.Text>
                      </InputGroup.Prepend>
                          <Form.Control as={DatePicker}
                          className={"text-black form-control"}
                          popperPlacement="right"
                            dateFormat="yyyy-MM-dd"
                            selected={this.state.estimateDate}
                            onChange={this.handleEstimateDateChange}
                          />
                    </InputGroup>
                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                        <InputGroup.Text style={{ width: "200px"}}>Amount of trays:</InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl value={this.state.requestedAmountTrays} onChange={(e) => {this.setState({requestedAmountTrays: e.target.value, requestedAmountPallets: Number(e.target.value / this.state.selectedItem.FormingsPerPallet).toFixed(1), requestedAmountBoxes: Number(e.target.value / this.state.selectedItem.FormingsPerBox).toFixed(1)})}}/>
                      <InputGroup.Prepend>
                        <InputGroup.Text style={{ width: "200px"}}> = {this.state.requestedAmountBoxes} boxes</InputGroup.Text>
                      </InputGroup.Prepend>
                    </InputGroup>
                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                        <InputGroup.Text style={{ width: "200px"}}>Amount of pallets:</InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl value={this.state.requestedAmountPallets} onChange={(e) => {this.setState({requestedAmountPallets: e.target.value, requestedAmountTrays: e.target.value * this.state.selectedItem.FormingsPerPallet, requestedAmountBoxes: Number(e.target.value * this.state.selectedItem.FormingsPerPallet / this.state.selectedItem.FormingsPerBox).toFixed(1)})}}/>
                      <InputGroup.Prepend>
                        <InputGroup.Text style={{ width: "200px"}}> = {this.state.requestedAmountBoxes} boxes</InputGroup.Text>
                      </InputGroup.Prepend>
                    </InputGroup>
                    <Button variant="warning" style={{ float: "right", marginBottom: "10px"}} onClick={this.sendProductionOrder.bind(this)}>SEND ORDER</Button>
                    </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="2">
                    ANOTHER REQUEST
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                  <Card.Body>
                    <Form.Control style={{ marginBottom: "20px", }} as="textarea" rows="5" value={this.state.anotherRequest} onChange={(e) => {this.setState({anotherRequest: e.target.value, })}} />
                    <Button variant="warning" style={{ float: "right", marginBottom: "10px", marginTop: "10px"}} onClick={this.sendRequest.bind(this)}>SEND REQUEST</Button>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>

          </Modal.Body>
        
          <Modal.Footer>
            <Button variant="secondary" onClick={()=> {this.setState({ sendMessageModal: false})}}>Close</Button>
          </Modal.Footer>
        </Modal>
        
      </div>
    );
  }
}

export default TableList2;
