/*!

*/
import React, { Component } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import axios from 'axios';
//import openSocket from 'socket.io-client';



class test extends Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {

    fetch('https://backend.customers-web.automation.multitray.nl/ob', {
      credentials: 'include' // fetch won't send cookies unless you set credentials

      })
      .then(response => response.json())
      .then(response => this.setState({
        body: response.body
      }));


    //this.getUser();

  }

  async getUser() {
    console.log("test.js - getUser");
    try {
      let response = await axios.get("https://backend.customers-web.automation.multitray.nl/ob");
      //return response;
      //this.setState({ body: response.json() });
      this.setState({ body: response });
    }
    catch (error) {
      console.error("ERR: " + error)
    }
  }





  render() {
var body = this.state.body;
    return (
      <div>
{body}
      </div>
    );
  }
}

export default test;
