/*!

*/
import TableList from "./views/TableList.jsx";
import TableList2 from "./views/TableList2.jsx";
import Documents from "./views/Documents.jsx";
import test from "./views/test.jsx";

const dashboardRoutes = [
/*  {
    path: "/customerInfo",
    name: "About Customer",
    icon: "pe-7s-user",
    component: AboutCustomer,
    layout: "/admin"
  },
*/
  {
    path: "/table",
    name: "Warehouse Info",
    icon: "pe-7s-box2",
    component: TableList,
    layout: "/admin",
    show: false
  },
  {
    path: "/view",
    name: "Warehouse Info",
    icon: "pe-7s-box2",
    component: TableList2,
    layout: "/admin",
    show: false
  },
  {
    path: "/documents",
    name: "Documents",
    icon: "pe-7s-box2",
    component: Documents,
    layout: "/admin",
    show: true
  },
];

export default dashboardRoutes;
