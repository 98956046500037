/*!

*/
import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import Sidebar from "../components/Sidebar/Sidebar";
import AdminNavbar from "../components/Navbars/AdminNavbar";

import "../views/index.css";
import routes from "../routes.js";

import image from "../assets/img/OpslagZeeland2.jpg";

class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _notificationSystem: null,
      image: image,
      hasImage: true,
    };
  }

  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            render={props => (
              <prop.component
                {...props}
              />
            )}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };
  componentDidMount() {

  }
  componentDidUpdate(e) {
  }


  render() {
    return (
      <div className="wrapper">
        <Sidebar {...this.props} routes={routes} image={this.state.image}
          hasImage={this.state.hasImage}
        />
        
        <div id="main-panel" className="main-panel" ref="mainPanel" style={{height:"100%"}}>
          <AdminNavbar brandText={this.getBrandText(this.props.location.pathname)} />
          <Switch>{this.getRoutes(routes)}</Switch>
        </div>
      </div>
    );
  }
}

export default Admin;
