import React, { Component } from "react";
import Navbar from "react-bootstrap/Navbar";
import NavItem from "react-bootstrap/NavItem";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import Gravatar from "react-gravatar";
import logo from "../../assets/img/Logo-Multitray.png";
import DataStore from '../DataStore.js';

class AdminNavbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fusionAuthBody: {},

    };
  }


  componentDidMount() {
    fetch('https://backend.customers-web.automation.multitray.nl/user', {
        credentials: 'include' // fetch won't send cookies unless you set credentials
      })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        this.setState({
          fusionAuthBody: response
        });
        if (typeof response.c1data != 'undefined') DataStore.setC1Data(response);

      })
  }

  render() {
    console.log(this.state.fusionAuthBody)
    let path = (this.state.fusionAuthBody.token) ?
      '/logout' :
      '/login';

    let btn = (this.state.fusionAuthBody.token) ?
      <Button type="submit" style={{ height: "100%", width: "100%", lineHeight: "50px", backgroundColor: "#8cc63f" }} href={"https://backend.customers-web.automation.multitray.nl" + path}>LOGOUT</Button> :
      <Button type="submit" style={{ height: "100%", width: "100%", lineHeight: "50px", backgroundColor: "#8cc63f" }} href={"https://backend.customers-web.automation.multitray.nl" + path}>LOGIN</Button>;

    let greeting = (this.state.fusionAuthBody.token) ?
      <span style={{ margin: "auto" }}>{this.state.fusionAuthBody.token.email}</span> :
      <span style={{ margin: "auto" }}>"You're not logged in."</span>;

    let gravatar = (this.state.fusionAuthBody.token) ?
      <Gravatar style={{ margin: "auto" }} email={this.state.fusionAuthBody.token.email} size={40} rating="pg" default="identicon" className="CustomAvatar-image" /> :
      <i className="pe-7s-user text-secondary" style={{ fontSize: "3em" ,fontWeight: "bold", margin: "auto" }} />;

    return (
      <div className="row">
        <div className="col-4">
          <img src={logo} alt="logo_image" style={{ width: "200px", margin: "10px"}} />
        </div>
        <div className="col-4" style={{ textAlign: "center"}}>
          <h4 className="title" style={{ }}>{this.props.brandText}</h4>
        </div>
        <div className="col-4">
          <div className="row" style={{ height: "100%"}}>
            <div className="col-8">
              <div className="row">
                {gravatar}
              </div>
              <div className="row">
                {greeting}
              </div>
            </div>
            <div className="col-4">
              {btn}
            </div>
          </div>
        </div>
      </div>

    );
  }
}

export default AdminNavbar;
